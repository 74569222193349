import React, { useState } from 'react'
import { DatePicker, Segmented, Table, TimePicker, Typography } from 'antd'
import StatisticCard from '../components/Partner/StatisticCard'
import { format } from '../helpers/number'
import { render } from 'react-dom';

const { Title } = Typography;

const Partner = () => {
	const [value, setValue] = useState<string | number>('วันนี้');

	const dataSource = [
		{
			date: '11/09/2024 01:47:05',
			member: '0806889391',
			amount: 10000000,
		},
		{
			date: '11/09/2024 01:47:05',
			member: '0806889391',
			amount: 1000,
		},
		{
			date: '11/09/2024 01:47:05',
			member: '0806889391',
			amount: 1000,
		},
		{
			date: '11/09/2024 01:47:05',
			member: '0806889391',
			amount: 1000,
		},
		{
			date: '11/09/2024 01:47:05',
			member: '0806889391',
			amount: 1000,
		},
		{
			date: '11/09/2024 01:47:05',
			member: '0806889391',
			amount: 1000,
		},
	];

	const columns = [
		{
			title: 'วันที่',
			dataIndex: 'date',
			key: 'date',
		},
		{
			title: 'สมาชิก',
			dataIndex: 'member',
			key: 'member',
			render: (member: string) => {
				return (
					<div style={{ whiteSpace: 'nowrap' }}>
						<div>{member}</div>
						<div style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.8)' }}>สมหมาย ชายเมือง</div>
					</div>
				)
			}
		},
		{
			title: 'ยอดเงิน',
			dataIndex: 'amount',
			key: 'amount',
			width: '200px',
			render: (amount: number) => {
				return (
					<div style={{ color: '#24d14f' }}>
						<span style={{ marginRight: 4 }}>฿</span>
						<span>{format(amount)}</span>
					</div>
				)
			}
		},
	];

	return (
		<div style={{ marginTop: 16 }}>
			<div className='transaction-card'>
				<div className='transaction-body' style={{ display: 'block' }}>

					{/* Filter */}
					<div className='filter-container'>
						<div className='range-picker-container'>
							<div className='range-picker'>
								<div className="range-picker-label">เริ่ม</div>
								<div className="range-picker-data">
									<DatePicker format='DD/MM/YYYY' />
								</div>
								<div className="range-picker-time">
									<TimePicker format="HH:mm" />
								</div>
							</div>
							<div className='range-picker'>
								<div className="range-picker-label">ถึง</div>
								<div className="range-picker-data">
									<DatePicker format='DD/MM/YYYY' />
								</div>
								<div className="range-picker-time">
									<TimePicker format="HH:mm" />
								</div>
							</div>
						</div>
						<Segmented
							options={['วันนี้', 'เมื่อวาน', 'เดือนนี้', 'เดือนที่แล้ว']}
							value={value}
							onChange={setValue}
						/>
					</div>

					{/* Summary */}
					<div className='summary'>
						<StatisticCard title="ยอดฝาก" amount={79994} item={3214} prefix='฿' className='green' />
						<StatisticCard title="ยอดถอน" amount={9994} item={1234} prefix='฿' className='red' />
						<StatisticCard title="กำไร" amount={9994} prefix='฿' className='blue' />
						<StatisticCard title="ยอดโบนัส" amount={1234} prefix='฿' className='geekblue' />
						<StatisticCard title="เปิดยูสใหม่" amount={9994} suffix='คน' className='volcano' />
						<StatisticCard title="ยูสฝากแรก" amount={9994} suffix='คน' className='purple' />
						<StatisticCard title="ยูสที่ฝาก" amount={9994} suffix='คน' className='magenta' />
						<StatisticCard title="รายได้" amount={1234} prefix='฿' className='cyan' />
					</div>

					{/* Top 10 */}
					<div className='list-card-container'>

						{/* ยอดฝากสูงสุด */}
						<div className='list-card'>
							<div className='list-card-head'>ยอดฝาก 10 อันดับ</div>
							<div className='list-card-body'>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
							</div>
						</div>

						{/* ยอดถอนสูงสุด */}
						<div className='list-card'>
							<div className='list-card-head'>ยอดถอน 10 อันดับ</div>
							<div className='list-card-body'>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
								<div className='list-card-item'>
									<div>
										<div className='list-card-item-user'>0888888888</div>
										<div className='list-card-item-bank'>สมหมาย ชายเมือง</div>
									</div>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div className='list-card-item-amount'>
											<span style={{ marginRight: 4 }}>฿</span>
											<span><span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(12345)}</span></span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* Table */}
					<div>
						{/* ฝาก */}
						<div className='table-card' style={{ marginBottom: 24 }}>
							<Title level={4} style={{ marginTop: 0 }}>รายการฝาก</Title>
							<Table
								dataSource={dataSource}
								columns={columns}
								scroll={{ x: true }}
								pagination={{ pageSize: 5 }}
							/>
						</div>

						{/* ถอน */}
						<div className='table-card'>
							<Title level={4} style={{ marginTop: 0 }}>รายการถอน</Title>
							<Table
								dataSource={dataSource}
								columns={columns}
								scroll={{ x: true }}
								pagination={{ pageSize: 5 }}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Partner

import React, { FC } from 'react';
import { Card } from 'antd';
import { format } from '../../helpers/number';

interface Props {
    title?: string
    className?: string
    amount?: number
    item?: number
    prefix?: string
    suffix?: string
}

const StatisticCard: FC<Props> = ({ title, className, amount = 0, item = 0, prefix, suffix }) => {
    return (
        <Card title={title} size='small' className={className}>
            <div>
                {prefix && <span style={{ marginRight: 4 }}>{prefix}</span>}
                <span style={{ fontSize: 20, fontWeight: 'bold' }}>{format(amount, prefix === '฿' ? 2 : 0)}</span>
                {suffix && <span style={{ marginLeft: 4 }}>{suffix}</span>}
            </div>
            {item !== 0 && <div style={{ fontSize: 14, color: 'rgba(255, 255, 255, 0.75)' }}>{format(item, 0)} รายการ</div>}
        </Card>
    )
}

export default StatisticCard;